import React from 'react'
import ImageWithBorders from './TestCard';
const Testimonial = () => {
  return (
    <>
    <div className='flex bg-[#1c1c1e] py-2 '>
        <ImageWithBorders/>

        </div>    
    </>
  )
}

export default Testimonial
